import React from 'react';
import Loadable from '@loadable/component';
// Style Imports
import './styles.scss';
// SEO
import Seo from '@components/utility/SEO';
import { getLocalizedURL } from '@src/helper';

// Structural Imports
import Layout from '@components/structure/Layout.jsx';
// Content Imports
import Hero from '@components/content/hero/Hero.jsx';
import Image from '@components/content/fractals-image.jsx';
const BloodIcon = Loadable(() => import('@components/content/icons/Blood.jsx'));
const CalendarLargeIcon = Loadable(() =>
	import('@components/content/icons/CalendarLarge.jsx')
);
const Cta = Loadable(() => import('@components/content/cta/CTA'));
const FootIcon = Loadable(() => import('@components/content/icons/Foot.jsx')); 
const ProteinIcon = Loadable(() => import('@components/content/icons/Protein.jsx'));
const UrineCupIcon = Loadable(() => import('@components/content/icons/UrineCup.jsx'));
const Video = Loadable(() => import('@components/content/video/Video.jsx'));

export const Head = () => (
	<>
		<script type='application/ld+json'
			dangerouslySetInnerHTML={{
				__html: JSON.stringify({
					"@context": "http://schema.org",
					"type": "VideoObject",
					"name": "Cómo compartir su historia de la nefritis lúpica | LUPKYNIS® (voclosporina)",
					"description": "Observe a las personas reales reaccionar ante su diagnóstico de nefritis lúpica y descubra el vínculo común entre cada uno de sus trayectos.",
					"thumbnailUrl": "https://www.lupkynis.com/images/taking-lupkynis/watch-real-people-stories-poster-pt-1.png",
					"uploadDate": "2022-10-01",
					"embedUrl": "https://www.lupkynis.com" + getLocalizedURL('what-is-lupus-nephritis','es'),
					"duration": "PT1M34S",
				})
			}}
		/>
	</>
)

const whatIsLupusNephritis = () => (
	<Layout addedClass='page--lupus-nephritis'>
		<Seo
			title='¿Qué es la nefritis lúpica? | LUPKYNIS&reg; (voclosporina)'
			description='La nefritis lúpica es una complicación grave del lupus que podría ser más frecuente de lo que usted cree. Consulte los signos, síntomas y opciones de tratamiento frecuentes para la nefritis lúpica. Consulte la Información de prescripción y seguridad completa y el RECUADRO DE ADVERTENCIA.'
			canonicalURL={"https://www.lupkynis.com" + getLocalizedURL('what-is-lupus-nephritis','es')}
		/>
		<Hero addedClass='bg-purple hero--string'>
			<div className='col-wrapper'>
				<div className='container'>
					<div className='hero__content'>
						<h1 className='heading heading--accent'>
						¿QUÉ ES LA NEFRITIS LÚPICA?
						</h1>
						<h2>
						La nefritis lúpica es una complicación frecuente y <span>grave</span> del lupus
						</h2>
						<p>
						La nefritis lúpica es una inflamación de los riñones causada por el lupus eritematoso sistémico (LES). Los riñones son órganos vitales, y la nefritis lúpica es una afección irreversible y progresiva que los pone en riesgo de complicaciones a largo plazo. Si no se trata, la inflamación causada por la nefritis lúpica destruye el tejido renal. Este daño irreversible afecta la función renal y puede provocar problemas de salud significativos, daño renal permanente e incluso insuficiencia renal, conocida como enfermedad renal terminal (ERT).
						</p>
					</div>
					<div className='hero__image'>
						<Image
							data={{
								desktopSrc: 'what-is-lupus-nephritis/hero-es.png',
								mobileSrc: 'what-is-lupus-nephritis/hero--mobile-es.png',
								altText: 'Malisha, diagnosticada en 2020',
							}}
						/>
					</div>
				</div>
			</div>
		</Hero>
		<section id='Common' className='bg-white text-center'>
			<div className='container'>
				<div className='copy-block'>
					<h2>La nefritis lúpica puede ser más frecuente de lo que usted cree</h2>
					<p>
					Aproximadamente 1 de cada 2 personas con lupus desarrolla nefritis lúpica con el tiempo. En comparación con las personas no hispanas de raza blanca, la prevalencia de la enfermedad es aproximadamente:
					</p>
				</div>
				<div className='col-wrapper text-left'>
					<div className='container'>
						<div className='icon-callout icon-callout--strong'>
							<div className='icon'>
								<Image
									data={{
										desktopSrc: 'what-is-lupus-nephritis/4-veces.png',
										altText: '4 veces',
										class: 'icon-four'
									}}
									width="121px"
								/>
							</div>
							<div>
								<p>
									<span className='sr-only'>4 veces</span> mayor para personas de ascendencia africana y asiática
								</p>
							</div>
						</div>
						<div className='icon-callout icon-callout--strong'>
							<div className='icon'>
								<Image
									data={{
										desktopSrc: 'what-is-lupus-nephritis/2-veces.png',
										altText: '2 veces',
									}}
									width="121px"
								/>
							</div>
							<div>
								<p>
									<span className='sr-only'>2 veces</span> mayor para las personas hispanas e indígenas
								</p>
							</div>
						</div>
					</div>
				</div>
				<hr />
				<div className='copy-block'>
					<h3 className='heading heading--alt'>
					Los signos y síntomas frecuentes de la nefritis lúpica son los siguientes:
					</h3>
				</div>
				<div className='icon-bar col-wrapper bg-light-pink'>
					<div className='container'>
						<div className='icon-bar__item'>
							<div className='icon'>
								<FootIcon />
							</div>
							<div className='icon-bar__content'>
								<p>
									<strong>Hinchazón</strong> (a menudo en los pies, los tobillos o las piernas)
								</p>
							</div>
						</div>
						<div className='icon-bar__item'>
							<div className='icon'>
								<UrineCupIcon />
							</div>
							<div className='icon-bar__content'>
								<p>
									<strong>Orina espumosa,</strong> burbujeante o jabonosa
								</p>
							</div>
						</div>
						<div className='icon-bar__item'>
							<div className='icon'>
								<BloodIcon />
							</div>
							<div className='icon-bar__content'>
								<p>
									<strong>Sangre en la orina</strong> (denominada hematuria)
								</p>
							</div>
						</div>
						<div className='icon-bar__item'>
							<div className='icon'>
								<ProteinIcon />
							</div>
							<div className='icon-bar__content'>
								<p>
									<strong>Proteína en la orina</strong> (denominada proteinuria)
								</p>
							</div>
						</div>
					</div>
				</div>
				<div className='copy-block'>
					<p>
						<strong>Los síntomas pueden ser sutiles o inconsistentes</strong> y a menudo superpuestos con otras enfermedades, por lo que su médico también debe monitorearlo para detectar signos de nefritis lúpica con análisis de sangre y de orina de rutina.
					</p>
				</div>
			</div>
		</section>
		<section id='WatchVideo' className='col-wrapper bg-light-pink'>
			<div className='container'>
				<div className='copy-block'>
					<h2>Vea a personas reales compartir sus historias</h2>
					<p>
					Vea cómo Malisha, Suriya y Jennifer reaccionaron ante su diagnóstico de nefritis lúpica y cómo descubrieron el vínculo común entre cada uno de sus recorridos únicos.
					</p>
				</div>
				<Video
					src='/videos/the-common-thread-of-lupus-nephritis-part1.mp4'
					type='video/mp4'
					poster='/images/taking-lupkynis/watch-real-people-stories-poster-pt-1.png'
					title='Vea a personas reales compartir sus historias' 
					controlsList='nodownload'
				/>
			</div>
		</section>
		<section id='RoutineTesting' className='bg-yellow'>
			<div className='container'>
				<div className='icon-callout'>
					<div className='icon'>
						<CalendarLargeIcon />
					</div>
					<div className='copy-block'>
						<p>
						Los análisis de orina de rutina buscan proteínas en la orina (un signo frecuente de nefritis lúpica), lo que puede ayudar a su médico a detectar la nefritis lúpica de manera temprana y comenzar a determinar un plan de tratamiento.
						</p>
						<p>
						Mantener un análisis de orina de rutina es fundamental para evitar la insuficiencia renal, la diálisis o un trasplante.
						</p>
					</div>
				</div>
			</div>
		</section>
		<section id='StandUp' className='col-wrapper bg-purple'>
			<div className='container'>
				<div className='copy-block'>
					<h2>Hacer frente a la nefritis lúpica</h2>
					<p>
						El objetivo del tratamiento de la nefritis lúpica es ayudar a detener el ataque a los riñones y protegerlos de daños permanentes. Hasta hace poco, no había tratamientos aprobados por la FDA <i>específicamente</i> para la nefritis lúpica. 
					</p>
					<p>
						<strong>Tratamientos comúnmente utilizados para la nefritis lúpica</strong>
					</p>
					<p>
						<strong>Inmunosupresores:</strong> se utilizan para tratar la nefritis lúpica agresiva o más grave. Estas son terapias poderosas que impiden que el sistema inmunitario dañe los riñones. 
					</p>
					<p>
						<strong>Esteroides:</strong> se utilizan para reducir y controlar rápidamente la inflamación de los riñones. Los esteroides pueden ayudar, pero depender de esteroides en dosis altas durante un período prolongado puede aumentar el riesgo de eventos adversos, como daño irreversible en los órganos.
					</p>
					<p>
						<strong>Medicamentos para la presión arterial:</strong> se utilizan para disminuir la presión arterial y también puede retrasar el progreso de la nefropatía.
					</p>
					<p>
						<strong>Antipalúdicos:</strong> a menudo se utilizan para tratar el lupus y también se ha demostrado que es beneficioso en el tratamiento de la nefritis lúpica.
					</p>

					<p className='orange-bar'>
					Crear un plan de tratamiento en torno a LUPKYNIS desde el principio puede elevar el nivel de cómo se trata la nefritis lúpica.
					</p>
				</div>
				<div className='image-block col-wrapper'>
					<div>
						<Image
							data={{
								desktopSrc: 'what-is-lupus-nephritis/warrior-es.png',
								mobileSrc: 'what-is-lupus-nephritis/warrior--mobile-es.png',
							}}
						/>
					</div>
					<Cta url={getLocalizedURL('what-is-lupkynis','es')} isCircleArrow={true}>
					Vea cómo LUPKYNIS eleva el nivel
					</Cta>
				</div>
			</div>
		</section>
	</Layout>
);

export default whatIsLupusNephritis;
